import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	inject,
} from '@angular/core';
import { VendorReportModel } from '../../models/VendorReport/VendorReportModel';
import { VendorReportService } from '../../services/vendor-report.service';
import { Column } from '../../components/electron/table/table.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportExportDialogComponent } from './dialogs/report-export.component';
import { ReportImportDialogComponent } from './dialogs/report-import.component';
import { VendorInputInsertModel } from '../../models/VendorReport/VendorInputInsertModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportSubmitDialogComponent } from './dialogs/report-submit.component';
import { UsersService } from '../../services/users.service';
import { BehaviorSubject, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { VendorContactInfo } from '../../models/Users/VendorContactInfo';
import { UserModel } from '../../models/Users/UserModel';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-vendor-report',
	templateUrl: './vendor-report.component.html',
	styleUrl: './vendor-report.component.scss',
})
export class VendorReportComponent implements OnInit, OnDestroy {
	@ViewChild('newLeadTimeTemplate', { static: true })
	newLeadTimeTemplate!: TemplateRef<void>;
	@ViewChild('commentTemplate', { static: true })
	commentTemplate!: TemplateRef<void>;
	@ViewChild('moduleNumTemplate', { static: true })
	moduleNumTemplate!: TemplateRef<void>;
	@ViewChild('lastLeadTimeTemplate', { static: true })
	lastLeadTimeTemplate!: TemplateRef<void>;

	readonly dialog = inject(MatDialog);
	protected _onDestroy = new Subject<void>();

	constructor(
		private readonly vendorReport: VendorReportService,
		private readonly userService: UsersService,
		private snackBar: MatSnackBar,
		private ref: ChangeDetectorRef,
	) {}
	ngOnDestroy(): void {
		this.userService.userInfo.unsubscribe();
		this._onDestroy.next();
		this._onDestroy.complete();
	}
	loading: boolean = false;
	reportColumns: Column[] = [];
	items: VendorReportModel[] = [];
	filterObj: any = {
		partNo: null,
		description: null,
	};
	vendorList: VendorContactInfo[] = [];
	isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	userInfo: Subject<UserModel | null> = new Subject<UserModel | null>();

	ngOnInit(): void {
		this.loadGridData();
		this.reportColumns = [
			new Column('ModelNum', 'Model #', true, this.moduleNumTemplate),
			new Column('Manufacturer', 'Manufacturer'),
			new Column('ItemShortDesc', 'Description'),
			new Column(
				'LastLeadTime',
				'Last Lead Time',
				true,
				this.lastLeadTimeTemplate,
			),
			new Column(
				'NewLeadTime',
				'Lead Time',
				true,
				this.newLeadTimeTemplate,
				'vertical-align-top',
			),
			new Column(
				'Comment',
				'Comments',
				true,
				this.commentTemplate,
				'vertical-align-top',
			),
		];
		this.userService.userInfo.subscribe((x) => this.handleAdminVendors(x));

		this.adminVendorSearch.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterAdminVendors();
			});
	}
	handleAdminVendors(x: UserModel | null) {
		const nextAdmin = x?.isAdmin === true;
		if (this.isAdmin.value !== nextAdmin) {
			this.adminVendorSearch.setValue(x?.Vendor?.VendorNumber ?? null);
			this.vendorReport
				.loadVendors()
				.subscribe((x: VendorContactInfo[]) => {
					this.vendorList = x;
					this.filteredVendorList.next(this.vendorList.slice());
				});
		}
		this.userInfo.next(x);
		this.isAdmin.next(nextAdmin);
	}
	vendorSelect(e: MatSelectChange) {
		if (e.value) {
			this.userService.setAdminVendor(e.value);
			this.loadGridData();
		}
	}
	@ViewChild('adminVendorSelect', { static: true })
	adminVendorSelect!: MatSelect;
	protected setInitialValue() {
		if (!this.filteredVendorList) return;

		this.filteredVendorList
			.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(() => {
				if (!this.adminVendorSelect) return;
				this.adminVendorSelect.compareWith = (
					a: VendorContactInfo,
					b: VendorContactInfo,
				) => a && b && a.VendorNumber === b.VendorNumber;
			});
	}
	adminVendorSearch: FormControl<string | null> = new FormControl<
		string | null
	>('');
	filteredVendorList: ReplaySubject<VendorContactInfo[]> = new ReplaySubject<
		VendorContactInfo[]
	>(1);
	filterAdminVendors(): void {
		if (!this.vendorList) return;
		if (!this.adminVendorSearch.value)
			this.filteredVendorList.next(this.vendorList.slice());

		this.filteredVendorList.next(
			this.vendorList.filter(
				(o) =>
					o.VendorName.toLowerCase().indexOf(
						this.adminVendorSearch.value?.toLowerCase() ?? '',
					) > -1,
			),
		);
	}
	loadGridData() {
		this.loading = true;
		this.vendorReport.get().subscribe((x: VendorReportModel[]) => {
			this.items = x;
			this.loading = false;
		});

		this.ref.detectChanges();
	}
	filterPointer = (itemData: any) => this.filterData(itemData);
	filterData(itemData: any): boolean {
		if (this.filterObj.partNo !== null) {
			if (
				itemData.ModelNum?.toLowerCase().indexOf(
					this.filterObj.partNo.toLowerCase(),
				) === -1 &&
				(itemData.ItemNumber === null ||
					itemData.ItemNumber?.toLowerCase().indexOf(
						this.filterObj.partNo.toLowerCase(),
					) === -1)
			) {
				return false;
			}
		}
		if (
			this.filterObj.description !== null &&
			(itemData.ItemShortDesc === null ||
				itemData.ItemShortDesc.toLowerCase().indexOf(
					this.filterObj.description.toLowerCase(),
				) === -1)
		) {
			return false;
		}
		return true;
	}

	exportData() {
		this.dialog.open(ReportExportDialogComponent);
	}
	importData() {
		const dialogRef = this.dialog.open(ReportImportDialogComponent);
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'saved') {
				this.loadGridData();
			}
		});
	}
	saveData() {
		const items = this.items.map(
			(i) =>
				<VendorInputInsertModel>{
					ItemNumber: i.ItemNumber,
					ModelNum: i.ModelNum,
					Manufacturer: i.Manufacturer,
					OldLeadTime: i.LastLeadTime,
					NewLeadTime: i.NewLeadTime,
					Comments: i.Comment,
				},
		);

		const dialogRef = this.dialog.open(ReportSubmitDialogComponent, {
			data: {
				items: items,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'saved') {
				this.loadGridData();
			}
		});
	}
	numberOnly(el: HTMLInputElement, item: any) {
		el.value = el.value.replace(/\D/g, '');
		let errMsg: string = '';
		if (el.value !== null) {
			if (item.LastLeadTime !== null) {
				if (
					parseInt(el.value) - 20 > parseInt(item.LastLeadTime) ||
					parseInt(el.value) + 20 < parseInt(item.LastLeadTime)
				) {
					errMsg = this.requiredText;
				}
			}
			if (parseInt(el.value) < 0) {
				errMsg = 'Lead time must be at least 0.';
				item.isErr = true;
			} else if (parseInt(el.value) > 3650) {
				errMsg = 'Lead time must be less than 10 years (3650 days).';
				item.isErr = true;
			} else {
				item.isErr = false;
			}
		}
		if (errMsg != null) {
			item.errorMessage = errMsg;
		}
	}
	requiredText: string =
		'Because the new lead time has changed by 21 days or more, please explain why.';
	isRequired(item: any): boolean {
		let isReq: boolean = false;
		if (item.NewLeadTime !== null) {
			if (item.LastLeadTime !== null) {
				if (
					parseInt(item.NewLeadTime) - 20 >
						parseInt(item.LastLeadTime) ||
					parseInt(item.NewLeadTime) + 20 <
						parseInt(item.LastLeadTime)
				) {
					isReq = true;
				}
			}
		}
		return isReq;
	}
}
