<ng-template #moduleNumTemplate let-element>
    <div class="flex items-center">
        <app-icon
            *ngIf="element.NeedsUpdate"
            src="/icons/base/alert-base.svg"
            style="
                border-color: var(--iux-ng-mat-theme-orange-secondary);
                color: var(--iux-ng-mat-theme-orange-secondary);
            "
            class="icon-16 mr-16 md:icon-32 flex-shrink-0 transform duration-300 rounded-full border-2 border-red-500 text-red-500 p-4"
            matTooltip="This item is overdue for a lead time update."
        ></app-icon>
        <div>
            Model #: {{ element.ModelNum ?? "N/A" }}<br />
            Duke #: {{ element.ItemNumber }}
        </div>
    </div>
</ng-template>
<ng-template #lastLeadTimeTemplate let-element>{{
    element.LastLeadTime === 2147483647 ? "N/A" : element.LastLeadTime
}}</ng-template>
<ng-template #newLeadTimeTemplate let-element>
    <mat-form-field [class]="element.isErr === true ? 'ng-invalid' : ''">
        <mat-label>New Lead Time (in days) </mat-label>
        <input
            matInput
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            [(ngModel)]="element.NewLeadTime"
            #t
            (input)="numberOnly(t, element)"
            [matTooltip]="element.errorMessage"
            [matTooltipDisabled]="!isRequired(element)"
            [required]="element.isErr === true"
        />
    </mat-form-field>
</ng-template>
<ng-template #commentTemplate let-element>
    <mat-form-field>
        <mat-label>Comment</mat-label>
        <textarea
            matInput
            type="text"
            [(ngModel)]="element.Comment"
            [matTooltip]="requiredText"
            [matTooltipDisabled]="!isRequired(element)"
            [required]="isRequired(element)"
        ></textarea>
    </mat-form-field>
</ng-template>
<electron-accordion title="How to use this form">
    <p class="font-bold">Updating Lead Times</p>
    <p>
        Once you have logged into the Duke Energy Vendor Lead Time website, you
        will see a list of items that your company is supplying to Duke Energy.
        We ask that you use this list to update lead times for your items by
        doing the following:
    </p>
    <ol>
        <li>
            Navigate to the “Lead Time” column:
            <ol>
                <li>
                    Input your updated lead time – in days – for each line item.
                    <ul>
                        <li>
                            Please ensure this is in
                            <span class="font-bold">numerical</span> format with
                            no symbols or letters (Ex. 45)
                        </li>
                        <li>Please do not provide date ranges.</li>
                    </ul>
                </li>
                <li>
                    If you see an exclamation point beside a line item, this
                    implies that items lead time needs to be updated.
                </li>
            </ol>
            <p class="italic">
                **Please note, you will see the current lead time we have for
                your item auto populated to the left. This is for reference
                purposes, only.
            </p>
        </li>
        <li>
            Please use the "Comment" field to notify us if there are any updates
            to an item such as the following:
            <ol>
                <li>
                    The lead time changes by 21 days or more (up or down),
                    please provide the reasoning for this change.
                </li>
                <li>
                    An item has been discontinued or superseded, along with the
                    replacement information.
                </li>
                <li>
                    An item is shipping overseas or internationally, please
                    provide a note annotating this.
                </li>
            </ol>
        </li>
        <li>
            Once you have filled in your information, press the “<span
                class="underline"
                >Submit Changes</span
            >” button to submit your updated lead times.
        </li>
    </ol>
    <p class="font-bold">
        **If you would like to Export your list to Excel to accomplish this
        request, please follow the below instructions:
    </p>
    <ol>
        <li>
            Navigate to the "Export to Excel" button at the top right of the
            grid.
        </li>
        <li>
            Click the "Export to Excel" button to generate an Excel spreadsheet
            of your items in a file named DataExport.xlsx.
        </li>
        <li>
            This workbook will be locked except for the two columns highlighted
            in green: the
            <span class="font-bold">New Lead Time field (Column F)</span> and
            the <span class="font-bold">Comment field (Column G)</span>.
            <ul>
                <li>
                    Fill in these fields using the same guidance provided above.
                </li>
            </ul>
        </li>
        <li>
            After you have completed the Excel spreadsheet, use the "Import
            Data" button at the top right of the grid on the website to upload
            your changes to DataExport.xlsx.
            <ul>
                <li>
                    If there are any errors loading the spreadsheet, they will
                    appear in the window after upload.
                </li>
                <li>
                    Please upload
                    <span class="font-bold underline">only</span> the Excel
                    sheet provided through the "Export to Excel" function.
                    <ul>
                        <li>Any custom spreadsheets will not be accepted.</li>
                    </ul>
                </li>
            </ul>
        </li>
    </ol>
</electron-accordion>
<electron-table
    [dataSource]="items"
    [columns]="reportColumns"
    [title]="'Supplier Lead Time Report'"
    [filterFunction]="filterPointer"
    [loading]="loading"
    [ShowRowNumber]="true"
>
    <div class="filter-bar" filters>
        <div class="left">
            <mat-form-field>
                <mat-label>Part Number</mat-label>
                <input matInput type="text" [(ngModel)]="filterObj.partNo" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Description</mat-label>
                <input
                    matInput
                    type="text"
                    [(ngModel)]="filterObj.description"
                />
            </mat-form-field>
            <mat-form-field *ngIf="isAdmin.value === true" style="flex-grow: 1">
                <mat-label>Vendor Filter</mat-label>
                <mat-select
                    (selectionChange)="vendorSelect($event)"
                    #adminVendorSelect
                >
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="adminVendorSearch"
                            placeholderLabel="Search Vendors"
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option value="">No Vendor Selected</mat-option>
                    <mat-option
                        *ngFor="let v of filteredVendorList | async"
                        [value]="v.VendorNumber"
                        >{{ v.VendorName }} ({{ v.VendorNumber }})</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div class="right px-8">
            <button mat-flat-button (click)="exportData()">
                Export to Excel
                <mat-icon iconPositionEnd>download_file</mat-icon>
            </button>
            <button mat-flat-button (click)="importData()">
                Import Data<mat-icon iconPositionEnd>upload_file</mat-icon>
            </button>
            <button mat-flat-button color="primary" (click)="saveData()">
                Submit Changes<mat-icon iconPositionEnd>chevron_right</mat-icon>
            </button>
        </div>
    </div>
</electron-table>
