import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
	selector: 'app-error-page',
	standalone: true,
	imports: [],
	templateUrl: './error.component.html',
	styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
	title: string = 'An Error Occurred';
	message: string = 'Please contact your administrator for more information.';

	constructor(private route: ActivatedRoute) {}
	ngOnInit(): void {
		this.route.params.subscribe((params: Params) => {
			if (params['errorCode']) {
				switch (params['errorCode']) {
					case 'NO_VENDOR': {
						this.message =
							'Your email is not set up correctly with a vendor in our system. Please contact your supply chain administrator to addresss this issue.';
						break;
					}
					case 'NOT_FOUND': {
						this.title = 'Route not found';
						if (params['lastUrl'] !== null) {
							this.message = `The route "${params['lastUrl']}" did not exist.`;
						} else {
							this.message = 'The route did not exist.';
						}
						break;
					}
				}
			}
		});
	}
}
