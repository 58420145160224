import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { VendorReportModel } from '../models/VendorReport/VendorReportModel';
import { Observable, map } from 'rxjs';
import { VendorReportImportModel } from '../models/VendorReport/VendorReportImportModel';
import { VendorInputInsertModel } from '../models/VendorReport/VendorInputInsertModel';
import { VendorContactInfo } from '../models/Users/VendorContactInfo';

@Injectable({
	providedIn: 'root',
})
export class VendorReportService {
	constructor(private http: HttpClient) {}

	get(): Observable<VendorReportModel[]> {
		return this.http
			.get<
				VendorReportModel[]
			>(`${environment.apiEndpoint}/VendorReport/GetItems`)
			.pipe(
				map(
					(data: any[]) =>
						<VendorReportModel[]>(
							data
								.map(VendorReportModel.adapt)
								.filter((x) => x !== null)
						),
				),
			);
	}

	export(): Observable<Blob> {
		return this.http
			.get(`${environment.apiEndpoint}/VendorReport/ExportAsExcel`, {
				responseType: 'blob',
				headers: {
					Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				},
			})
			.pipe(map((value: Blob) => value));
	}

	importVendorInput(file: File): Observable<any> {
		const formData = new FormData();
		formData.append('files', file);
		return this.http.post(
			`${environment.apiEndpoint}/VendorReport/ImportAsExcel`,
			formData,
			{
				reportProgress: true,
				observe: 'events',
			},
		);
	}
	saveVendorInput(
		items: VendorInputInsertModel[],
	): Observable<VendorReportImportModel | null> {
		return this.http
			.post(
				`${environment.apiEndpoint}/VendorReport/SaveVendorInput`,
				JSON.stringify(items),
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
					},
				},
			)
			.pipe(map(VendorReportImportModel.adapt));
	}

	loadVendors(): Observable<VendorContactInfo[]> {
		return this.http
			.get<
				VendorContactInfo[]
			>(`${environment.apiEndpoint}/VendorReport/GetVendors`)
			.pipe(
				map(
					(data: any[]) =>
						<VendorContactInfo[]>(
							data
								.map(VendorContactInfo.adapt)
								.filter((x) => x !== null)
						),
				),
			);
	}
}
